import { el, svg } from 'redom';
import marker from './marker.js';


export default class Search{
	constructor(){
		this.query = '';
		this.position = null;
		this.map = null;
		this.geocoder = new google.maps.Geocoder();
		this.places = null;
		this.markers = [];
		this.toofar = [];
		this.noresults = [];
		this.placeselect = [];
		this.openfield = [];

		this.el = el('.sps__search',
			el('.sps__search-field',
				this.$searchField = el('input.sps__search-input', { type: 'text', id: 'sps-search-field', required: true }),
				this.$searchLabel = el('label.sps__search-label', {  }, 'Podaj swój adres'),
				this.$searchError = el('label.sps__search-error', {  })
			),
			this.$searchButton = el('.sps__search-button',
				this.$searchTrigger = el('.sps__search-trigger',
					svg('svg', { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 485.213 485.213' }, 
						svg('path', { d: 'M363.909 181.955C363.909 81.473 282.44 0 181.956 0 81.474 0 .001 81.473.001 181.955s81.473 181.951 181.955 181.951c100.484 0 181.953-81.469 181.953-181.951zM181.956 318.416c-75.252 0-136.465-61.208-136.465-136.46 0-75.252 61.213-136.465 136.465-136.465 75.25 0 136.468 61.213 136.468 136.465 0 75.252-61.218 136.46-136.468 136.46zM471.882 407.567L360.567 296.243a214.267 214.267 0 0 1-64.331 64.321L407.56 471.888c17.772 17.768 46.587 17.768 64.321 0 17.773-17.739 17.773-46.554.001-64.321z' })
					)
				),
				this.$closeTrigger = el('.sps__search-close',
					svg('svg', { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 47.971 47.971' }, 
						svg('path', { d: 'M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88 c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242 C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879 s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z' })
					)
				)	
			)
		);

		this.$searchTrigger.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();

			if(!this.el.classList.contains('opened')){
				this.el.classList.add('opened');
				this.onopenfield();
			}else{
				this.search();
			}
		});

		this.$closeTrigger.addEventListener('click', e => {
			e.preventDefault();
			e.stopPropagation();

			this.close();
		});

		this.$searchLabel.addEventListener('click', e => {
			this.$searchField.focus();
		});

		this.$searchField.addEventListener('keyup', e => {
			if(e.keyCode === 13){
				this.search();
			}
		});

		window.addEventListener('resize', () => this.fontSize());
	}

	fontSize(){
		this.el.style.fontSize = this.el.offsetHeight + 'px';
	}

	onmount(){
		this.fontSize();
		this.places = new google.maps.places.PlacesService(this.map.map);
		this.update();
	}

	search(){
		if(this.empty()) return;
		if(this.same()){
			if(null !== this.position){
				this.map.center(this.position);
				this.map.zoom(16);
				this.update();
			}
			return;
		}

		this.query = this.value(); 
		this.geocoder.geocode( { 'address': this.value() }, (results, status) => {
      		if (status == 'OK') {
        		this.position = results[0].geometry.location;
        		this.map.center(this.position);
        		this.map.zoom(16);
        		this.update();
			} else {
				alert('Geocode was not successful for the following reason: ' + status);
			}
    	});
	}

	close(){
		this.el.classList.remove('opened');
		this.$searchField.value = '';
	}

	value(){
		return this.$searchField.value;
	}

	empty(){
		return this.value() === '';
	}

	same(){
		return this.value() === this.query;
	}

	ontoofar(fn = null){
		if(null === fn){
			for(let i = this.toofar.length; i--;){
				this.toofar[i].call(this);
			}
		}else{
			this.toofar.push(fn);
		}
	}

	onnoresults(fn = null){
		if(null === fn){
			for(let i = this.noresults.length; i--;){
				this.noresults[i].call(this);
			}
		}else{
			this.noresults.push(fn);
		}
	}

	onplaceselect(arg){
		if(typeof arg === 'function'){
			this.placeselect.push(arg);
		}else{
			for(let i = this.placeselect.length;i--;){
				this.placeselect[i].call(this, arg);
			}
		}
	}

	onopenfield(fn = null){
		if(null === fn){
			for(let i = this.openfield.length; i--;){
				this.openfield[i].call(this);
			}
		}else{
			this.openfield.push(fn);
		}
	}

	update(useBestResult = true){
		if( this.map.zoom() < 14 ) {
			this.ontoofar();
			while(this.markers.length){
				this.markers.shift().hide();
			}
			return;
		}

		let query = {
			type: ['pharmacy'],
			language: 'pl',
			location: this.map.center(),
			radius: Math.round(1000000000 / (this.map.zoom()**5))
		};

		var best = null;
		var diff = [];
		this.places.nearbySearch(query, (response) => {
			if(response.length){
				for(let i = response.length; i--;){
					let markerExists = false;
					for(let j = this.markers.length; j--;){
						if(response[i].id === this.markers[j].id){
							markerExists = true;
							diff.push(response[i].id);
						}
					}

					if(!markerExists){
						let place = marker({
					    	position: response[i].geometry.location,
					    	map: this.map.map,
					    	id: response[i].id,
					    	place: response[i].place_id
						});

						place.addListener('click', () => {
							if(null === place.details){
								let details = this.places.getDetails({
									placeId: place.place,
									fields: [ 'name', 'formatted_phone_number', 'formatted_address', 'photo', 'opening_hours', 'website' ]
								}, (result) => {
									place.details = result;
									this.onplaceselect(place);
								});
							}else{
								this.onplaceselect(place);
							}
						});

						this.markers.push(place);
					}

					if(null === best || response[i].rating > best.rating){
						best = response[i];
					}
				}

				if(useBestResult){
					//this.map.center(best.geometry.location, true);
				}
			}else{
				this.onnoresults();
			}

			let newMarkes = [];
			for(let i = this.markers.length; i--;){
				if(-1 === diff.indexOf(this.markers[i].lenght)){
					newMarkes.push(this.markers[i]);
				}else{
					this.markers[i].hide();
				}
			}

			this.markers = newMarkes;
		});
	}
}

