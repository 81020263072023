
import { el, mount } from 'redom';

export default function($appScript){
	return new Promise(function(done){
		const fallback = () => {
			window.geoip = data => done({
				position: {
					lat: parseFloat(data.latitude),
					lng: parseFloat(data.longitude)
				},
				type: 'geoip'
				
			});
			const $geoScript = el('script', { src: 'https://get.geojs.io/v1/ip/geo.js' });
			$appScript.parentElement.insertBefore($geoScript, $appScript);
		};

		setTimeout(() => {
			navigator.geolocation ? navigator.geolocation.getCurrentPosition( data => done({ 
				position: {
					lat: data.coords.latitude, 
					lng: data.coords.longitude 
				},
				type: 'geolocation'
			}), fallback()) : fallback();
		}, 1000);
	});
}
