import 'styles/index.scss';
import 'current-script-polyfill/currentScript.js';
import geo from './geo.js';
import Map from './map.js';
import Alert from './alert.js';
import Search from './search.js';
import Card from './card.js';
import { el, mount } from 'redom';
import key from './key.js';

const $appScript = document.currentScript;
const apiKey = document.currentScript.getAttribute('data-key');
const $gaScript = el('script', { src: 'https://maps.googleapis.com/maps/api/js?key='+apiKey+'&callback=initMap&libraries=places' });
const $root = el('.sps');

key(apiKey);

$appScript.parentElement.insertBefore($gaScript, $appScript);
$appScript.parentElement.insertBefore($root, $gaScript);

window.initMap = () => geo($appScript).then(result => {
    const map = new Map({
        center: result.position,
        zoom: result.type === 'geolocation' ? 16 : 14
    });
    const search = new Search();
    const alert = new Alert();
    const card = new Card();

    map.onmapopen(() => {
        search.map = map;
        mount($root, search);
    });

    map.onmapchange(() => {
        search.update(null, true);
        card.hide();
        search.close();
    });

    search.ontoofar(() => {
        alert.show('Przybliż mapę')
    });

    search.onplaceselect((place) => {
        search.close();
        card.show(place);
    });

    search.onopenfield(() => {
        card.hide();
    });

    mount($root, alert);
    mount($root, map);
    mount($root, card);
});