import { el, text } from 'redom';

export default class Alert{

	constructor(){
		this.el = el('.sps__alert',
			el('.sps__alert-container',
				this.content = text('')
			)
		);
		this.isVisible = false;
		this.timeout = null;
	}

	show(text){
		this.content.textContent = text;

		if(null !== this.timeout){
			clearTimeout(this.timeout);
		}
		
		if(!this.isVisible){
			this.el.classList.add('visible');
		}

		this.timeout = setTimeout(() => {
			this.el.classList.add('closing');
			this.el.classList.remove('visible');
			setTimeout(() => {
				this.content.textContent = '';	
				this.el.classList.remove('closing')
			}, 1000);
		}, 1000);
	}

	onmount(){
		window.addEventListener('resize', () => this.fontSize());
		this.fontSize();
	}

	fontSize(){
		this.el.style.fontSize = this.el.offsetHeight + 'px';
	}

}