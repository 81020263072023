import { el, text, svg } from 'redom';

export default class Card{

	constructor(){
		this.el = el('.sps__card',
			this.$image = el('.sps__card-image',
				el('.sps__card-preloader',
					svg('svg', { viewBox: '0 0 44 44', xmlns:'http://www.w3.org/2000/svg', stroke: '#fff' }, 
						svg('g', { strokeWidth: 2, fill: 'none' },
							svg('circle', { cx: 22, cy: 22, r: 1 },
								svg('animate', { 
									attributeName: 'r', begin: '0s', dur:'1.8s', values:'1;20', calcMode: 'spline', 
									keyTimes:'0; 1', keySplines:'0.165, 0.84, 0.44, 1', repeatCount: 'indefinite'
								}),
								svg('animate', {
									attributeName: 'stroke-opacity', begin: '0s', dur: '1.8s', values:'1; 0', calcMode: 'spline',
									keyTimes: '0; 1', keySplines: '0.3, 0.61, 0.355, 1', repeatCount: 'indefinite'
								})
							),
							svg('circle', { cx: 22, cy: 22, r: 1 },
								svg('animate', { 
									attributeName: 'r', begin: '-0.9s', dur:'1.8s', values:'1;20', calcMode: 'spline', 
									keyTimes:'0; 1', keySplines:'0.165, 0.84, 0.44, 1', repeatCount: 'indefinite'
								}),
								svg('animate', {
									attributeName: 'stroke-opacity', begin: '-0.9s', dur: '1.8s', values:'1; 0', calcMode: 'spline',
									keyTimes: '0; 1', keySplines: '0.3, 0.61, 0.355, 1', repeatCount: 'indefinite'
								})
							)
						)
					)
				)
			), 
			this.$content = el('.sps__card-content', 
				el('.sps__card-name',
					this.$name = text('')
				),
				el('.sps__card-address',
					this.$address = text('')
				),
				this.$phoneLink = el('a.sps__card-phone', { href: '#' },
					svg('svg', { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 348.077 348.077' },
						svg('path', { d: 'M340.273,275.083l-53.755-53.761c-10.707-10.664-28.438-10.34-39.518,0.744l-27.082,27.076 c-1.711-0.943-3.482-1.928-5.344-2.973c-17.102-9.476-40.509-22.464-65.14-47.113c-24.704-24.701-37.704-48.144-47.209-65.257 c-1.003-1.813-1.964-3.561-2.913-5.221l18.176-18.149l8.936-8.947c11.097-11.1,11.403-28.826,0.721-39.521L73.39,8.194 C62.708-2.486,44.969-2.162,33.872,8.938l-15.15,15.237l0.414,0.411c-5.08,6.482-9.325,13.958-12.484,22.02 C3.74,54.28,1.927,61.603,1.098,68.941C-6,127.785,20.89,181.564,93.866,254.541c100.875,100.868,182.167,93.248,185.674,92.876 c7.638-0.913,14.958-2.738,22.397-5.627c7.992-3.122,15.463-7.361,21.941-12.43l0.331,0.294l15.348-15.029 C350.631,303.527,350.95,285.795,340.273,275.083z' })
					),
					el('span', 
						this.$phone = text('')
					)
				),
				this.$wwwLink = el('a.sps__card-www', { href: '#', target:'_blank' },
					svg('svg', { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 612 612' },
						svg('path', { d: 'M458.388,230.724c0-3.672-1.428-6.528-4.284-8.568L313.344,113.22 c-4.08-3.264-8.568-3.264-13.464,0L164.016,222.156c-2.448,2.04-3.672,4.896-3.672,8.568v196.452c0,3.267,1.02,5.916,3.06,7.956 s4.488,3.063,7.344,3.063h80.784c3.264,0,5.916-1.021,7.956-3.063c2.04-2.04,3.06-4.689,3.06-7.956V306.613h93.024v120.562 c0,3.267,1.02,5.916,3.06,7.956s4.692,3.063,7.956,3.063h80.784c3.264,0,5.916-1.021,7.956-3.063s3.06-4.689,3.06-7.956V230.724z M306,0c42.024,0,81.702,8.058,119.034,24.174s69.768,37.944,97.308,65.484s49.368,59.976,65.484,97.308S612,263.976,612,306 c0,28.152-3.672,55.284-11.016,81.396c-7.347,26.109-17.646,50.487-30.906,73.134c-13.26,22.644-29.172,43.248-47.736,61.812 c-18.562,18.564-39.168,34.479-61.812,47.736c-22.646,13.26-47.022,23.562-73.136,30.906C361.284,608.328,334.152,612,306,612 s-55.284-3.672-81.396-11.016c-26.112-7.347-50.49-17.646-73.134-30.906s-43.248-29.172-61.812-47.736 c-18.564-18.562-34.476-39.168-47.736-61.812c-13.26-22.646-23.562-47.022-30.906-73.135C3.672,361.284,0,334.152,0,306 s3.672-55.284,11.016-81.396s17.646-50.49,30.906-73.134s29.172-43.248,47.736-61.812s39.168-34.476,61.812-47.736 s47.022-23.562,73.134-30.906S277.848,0,306,0z' })
					),
					el('span', 
						this.$www = text('')
					)
				),
				this.$opened = el('.sps__card-opened', 
					this.$openedText = text('')
				)
			),
		);
		this.isVisible = false;
		this.timeout = null;
	}

	hide(){
		this.el.classList.add('closing');
		this.el.classList.remove('opened');
		setTimeout(() => {
			this.el.classList.remove('closing');
		}, 600);
	}

	show(place){
		this.el.classList.add('opened');
		this.$image.classList.add('loading');

		this.$name.textContent = place.details.name;
		this.$address.textContent = place.details.formatted_address;

		if(typeof place.details.formatted_phone_number === 'undefined'){
			this.$phoneLink.classList.add('hidden');
		}else{
			this.$phoneLink.classList.remove('hidden');
			this.$phoneLink.setAttribute('href', 'tel:'+place.details.formatted_phone_number.replace(/\s+/, ''));
			this.$phone.textContent = place.details.formatted_phone_number
		}

		if(typeof place.details.website === 'undefined'){
			this.$wwwLink.classList.add('hidden');
		}else{
			this.$wwwLink.classList.remove('hidden');
			this.$wwwLink.setAttribute('href', place.details.website);
			this.$www.textContent = place.details.website;
		}

		if(typeof place.details.opening_hours === 'undefined'){
			this.$opened.classList.add('hidden');
		}else{	
			this.$opened.classList.remove('hidden');
			if(place.details.opening_hours.open_now){
				this.$opened.classList.add('opened');
				this.$openedText = 'Otwarte';
			}else{
				this.$opened.classList.remove('opened');
				this.$openedText = 'Zamknięte';
			}
		}

		let image = new Image();
		image.onload = () => {
			this.$image.style.backgroundImage = 'url('+place.getPhoto()+')';
			this.$image.classList.remove('loading');
		};
		image.src = place.getPhoto();
	}

	onmount(){
		window.addEventListener('resize', () => this.fontSize());
		this.fontSize();
	}

	fontSize(){
		this.el.style.fontSize = this.el.offsetHeight + 'px';
	}

}