import options from 'merge-options';
import { el } from 'redom';

const CONFIG_MAP = {
    center: {
        lat: 52.229850, 
        lng: 21.011696
    },
    zoom: 16,
    disableDefaultUI: true,
    styles: [{
        featureType: "poi",
        elementType: "labels",
        stylers: [
            { visibility: "off" }
        ]
    }]
};

export default class Map {
	constructor(config = {}){
        this.el = el('.sps__map');
        this.config = options(CONFIG_MAP, config);
		this.map = null;
        this.mapopen = [];
        this.mapchange = [];
        this.timeout = null;
        this.ignoreEvent = false;
    }

    onmount(){
        this.map = new google.maps.Map(this.el, this.config); 
        this.onmapopen();
        this.map.addListener('center_changed', (e) => this.handleMapChangeEvent());
        this.map.addListener('zoom_changed', (e) => this.handleMapChangeEvent());
    }

    handleMapChangeEvent(){
        if(null !== this.timeout){
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.timeout = null;
            this.onmapchange();
        }, 1000);
    }

    onmapopen(fn = null){
        if(null === fn){
            for(let i = this.mapopen.length; i--;){
                this.mapopen[i].call(this);
            }
        }else{
            this.mapopen.push(fn);
        }
    }

    onmapchange(fn = null){
        if(null === fn){
            if(this.ignoreEvent){
                this.ignoreEvent = false;
                return;
            }
            
            for(let i = this.mapchange.length; i--;){
                this.mapchange[i].call(this);
            }
        }else{
            this.mapchange.push(fn);
        }
    }

	center(position = null, ignoreEvent = false){
        if(null === position){
            return this.map.getCenter();
        }
        this.ignoreEvent = ignoreEvent;
        this.map.setCenter(position);
	}

    zoom(zoom = null){
        if(null === zoom){
            return this.map.getZoom();
        }

        this.map.setZoom(zoom);
    }
}

