import { el, svg } from 'redom';
import key from './key.js';

const createHTMLMapMarker = ({ OverlayView = google.maps.OverlayView,  ...args }) => {
  	class HTMLMapMarker extends OverlayView {
	    constructor() {
		    super();
		    this.latlng = args.position;
		    this.id = args.id;
		    this.place = args.place;
		    this.setMap(args.map);
		    this.details = null;
	  	}

	  	draw() {
		 	if (!this.div) {
		    	this.div = el('.sps__marker', {style:{position: 'absolute', width: '30px', height: '40px'}},
		    		svg('svg', { viewBox: '0 0 365 486', xmlns: 'http://www.w3.org/2000/svg' },
		    			svg('path.marker', { d: 'M182.606 0C82.124 0 .651 81.473.651 181.955c0 100.482 151.631 303.257 181.956 303.257 30.326 0 181.955-202.775 181.955-303.257S283.089 0 182.606 0zm0 303.257c-66.9 0-121.302-54.433-121.302-121.302S115.706 60.651 182.606 60.651c66.902 0 121.302 54.435 121.302 121.304s-54.399 121.302-121.302 121.302z' }),
		    			svg('path.symbol', { d: 'M198.138 276h-30.265c-6.067 0-11.003-4.95-11.003-11.033v-23.803c0-2.777-2.33-4.494-4.486-4.494a4.4 4.4 0 0 0-2.362.698l-24.104 15.144a10.973 10.973 0 0 1-5.834 1.684c-3.82 0-7.309-1.936-9.334-5.18l-16.067-25.718a10.97 10.97 0 0 1-1.398-8.327 10.964 10.964 0 0 1 4.88-6.883l29.034-18.237a4.402 4.402 0 0 0 2.1-3.796c0-1.576-.764-2.962-2.097-3.797L98.165 164.02a10.964 10.964 0 0 1-4.88-6.882 10.97 10.97 0 0 1 1.398-8.328l16.067-25.715a10.943 10.943 0 0 1 9.334-5.18c2.064 0 4.08.584 5.834 1.685l24.107 15.144a4.38 4.38 0 0 0 2.359.698c2.156 0 4.486-1.717 4.486-4.495V97.033c0-6.083 4.936-11.033 11.003-11.033h30.265c6.067 0 11.003 4.95 11.003 11.033v33.732c0 1.592.738 2.63 1.356 3.223 1.463 1.399 3.76 1.684 5.528.547l24.012-15.455a10.935 10.935 0 0 1 5.936-1.753c3.819 0 7.308 1.936 9.33 5.18l16.022 25.643c3.218 5.156 1.656 11.976-3.483 15.21L237.75 182.26c-1.333.836-2.097 2.221-2.097 3.797s.76 2.961 2.097 3.8l30.092 18.902c5.139 3.23 6.704 10.053 3.483 15.21l-16.002 25.61a10.942 10.942 0 0 1-9.33 5.18c-2.124 0-4.192-.617-5.982-1.78l-23.967-15.593a4.371 4.371 0 0 0-2.414-.737c-2.156 0-4.489 1.72-4.489 4.498v23.822c0 6.08-4.936 11.03-11.003 11.03z' })
	    			)
	    		);

		    	google.maps.event.addDomListener(this.div, 'click', event => {
		      		google.maps.event.trigger(this, 'click');
		    	});

		    	const panes = this.getPanes();
		    	panes.overlayImage.appendChild(this.div);    
		  	}

		  	const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
		  	if (point) {
		    	this.div.style.left = `${point.x}px`;
		    	this.div.style.top = `${point.y}px`;
		  	}
		}

		getPhoto() {
			if(null === this.details || typeof this.details.photos === 'undefined'){
				return 'https://maps.googleapis.com/maps/api/streetview?'+
					'size=640x640&location='+this.latlng.lat()+','+this.latlng.lng()+
					'&fov=90&pitch=0&sensor=false&key='+key()
			}

			return this.details.photos[0].getUrl();
		}

		remove() {
		  	if (this.div) {
		    	this.div.parentNode.removeChild(this.div);
		    	this.div = null;
		  	}
		}

		getPosition() {
		  	return this.latlng;
		}

		getDraggable() {
		  	return false;
		}

		hide(){
			if(this.div){
				this.div.style.opacity = 0;
				setTimeout(() => {
					this.setMap(null);
				},300);
			}
		}
  	}	

  	return new HTMLMapMarker();
};

export default createHTMLMapMarker;